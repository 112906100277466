<template>
  <div class="convertibleBonds">
    <div class="topPart" style="height: 200px">
      <div class="form-item">
        <div class="inputBox">
          <div class="title">债券代码</div>
          <Input
            v-model="params.zqdm"
            placeholder="债券代码"
            style="margin-right: 50px; width: 250px"
          />
        </div>
        <div class="inputBox">
          <div class="title">债券简称</div>
          <Input
            v-model="params.zqjc"
            placeholder="债券简称"
            style="margin-right: 50px; width: 250px"
          />
        </div>
        <div class="inputBox">
          <div class="title">正股代码</div>
          <Input
            v-model="params.zgdm"
            placeholder="正股代码"
            style="width: 250px"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="inputBox">
          <div class="title">正股简称</div>
          <Input
            v-model="params.zgjc"
            placeholder="正股简称"
            style="width: 250px; margin-right: 50px"
          />
        </div>
        <span class="title">转股开始日</span>
        <DatePicker
          :type="'date'"
          v-model="params.zgksr[0]"
          @on-change="getTimeStartzg"
          format="yyyy-MM-dd"
          placeholder="开始时间"
          style="width: 140px; margin-right: 5px"
        ></DatePicker
        >至
        <DatePicker
          :type="'date'"
          v-model="params.zgksr[1]"
          @on-change="getTimeEndzg"
          format="yyyy-MM-dd"
          placeholder="结束时间"
          style="width: 140px; margin: 0 50px 0 5px"
        ></DatePicker>
        <span class="title">上市日</span>
        <DatePicker
          :type="'date'"
          @on-change="getTimeStartss"
          format="yyyy-MM-dd"
          v-model="params.ssr[0]"
          placeholder="开始时间"
          style="width: 140px; margin-right: 5px"
        ></DatePicker
        >至
        <DatePicker
          :type="'date'"
          v-model="params.ssr[1]"
          @on-change="getTimeEndss"
          format="yyyy-MM-dd"
          placeholder="结束时间"
          style="width: 140px; margin: 0 50px 0 5px"
        ></DatePicker>
        <span class="title">起息日</span>
        <DatePicker
          :type="'date'"
          v-model="params.qxr[0]"
          @on-change="getTimeStartqx"
          format="yyyy-MM-dd"
          placeholder="开始时间"
          style="width: 140px; margin-right: 5px"
        ></DatePicker
        >至
        <DatePicker
          :type="'date'"
          v-model="params.qxr[1]"
          @on-change="getTimeEndqx"
          format="yyyy-MM-dd"
          placeholder="结束时间"
          style="width: 140px; margin: 0 50px 0 5px"
        ></DatePicker>
      </div>
      <Button
        class="searchBtnLog" data-id="1"
        type="info"
        style="width: 144px; display: block; margin: 0 auto"
        @click="getKzz"
        >查询</Button
      >
    </div>
    <div class="middlePart">
      <span>已选条件：</span>

      <Tag
        type="border"
        v-if="params.zqdm"
        size="large"
        @on-close="params.zqdm = ''"
        closable
      >
        债券代码:
        <span class="tabContent">{{ params.zqdm }}</span>
      </Tag>
      <Tag
        type="border"
        v-if="params.zqjc"
        size="large"
        @on-close="params.zqjc = ''"
        closable
      >
        债券简称:
        <span class="tabContent">{{ params.zqjc }}</span>
      </Tag>
      <Tag
        type="border"
        v-if="params.zgdm"
        size="large"
        @on-close="params.zgdm = ''"
        closable
      >
        正股代码:
        <span class="tabContent">{{ params.zgdm }}</span>
      </Tag>
      <Tag
        type="border"
        v-if="params.zgjc"
        size="large"
        @on-close="params.zgjc = ''"
        closable
      >
        正股简称:
        <span class="tabContent">{{ params.zgjc }}</span>
      </Tag>
      <Tag
        type="border"
        v-if="params.zgksr[0] && params.zgksr[1]"
        size="large"
        @on-close="params.zgksr = []"
        closable
      >
        转股开始日:
        <span class="tabContent"
          >{{ params.zgksr[0] }}-{{ params.zgksr[1] }}</span
        >
      </Tag>
      <Tag
        type="border"
        v-if="params.ssr[0] && params.ssr[1]"
        size="large"
        @on-close="params.ssr = []"
        closable
      >
        上市日:
        <span class="tabContent">{{ params.ssr[0] }}-{{ params.ssr[1] }}</span>
      </Tag>
      <Tag
        type="border"
        v-if="params.qxr[0] && params.qxr[1]"
        size="large"
        @on-close="params.qxr = []"
        closable
      >
        起息日:
        <span class="tabContent">{{ params.qxr[0] }}-{{ params.qxr[1] }}</span>
      </Tag>
    </div>
    <div class="bottomPart">
      <Table
        border
        :loading="tableLoading"
        tooltip-theme="light"
        :columns="stockColumns"
        :data="stockTable"
      ></Table>
      <div style="text-align: right; padding: 20px">
        <Page :total="total" show-elevator @on-change="pageChange" />
      </div>
    </div>
  </div>
</template>

<script>
import { queryKzz } from "@/api/financial/debt";
import { getQy } from "@/api/public";
import dataReports from "@/mixins/dataReports";
export default {
  name: "convertibleBonds",
  mixins: [dataReports],
  data() {
    return {
      bondname: "",
      bondcode: "",
      stockcode: "",
      stockname: "",
      convert: {
        timeStart: "",
        timeEnd: "",
      },
      onMarket: {
        timeStart: "",
        timeEnd: "",
      },
      rise: {
        timeStart: "",
        timeEnd: "",
      },
      stockTable: [],
      stockColumns: [
        {
          title: "债券代码",
          align: "center",
          key: "zqdm",
          width: 150,
          ellipsis: true,
          tooltip: true,

          render: (h, params) => {
            return h(
              "a",
              {
                attrs: {
                  class: "btn-detail",
                  title: params.row.zqdm,
                },
                on: {
                  click: () => {
                    // this.$router.push({
                    //     path: `/Insight/financialMarket/financialProduct/Bond/convertibleDetail`,
                    //     query: {
                    //         id: params.row.id
                    //     }
                    // });
                    let routeData = this.$router.resolve({
                      path: `/Insight/financialMarket/financialProduct/Bond/convertibleDetail`,
                      query: {
                        id: params.row.id,
                      },
                    });
                    window.open(routeData.href, "_blank");
                  },
                },
              },
              params.row.zqdm
            );
          },
        },
        {
          title: "债券简称",
          align: "center",
          key: "zqjc",
          ellipsis: true,
          width: 150,
          tooltip: true,

          render: (h, params) => {
            return h(
              "a",
              {
                attrs: {
                  class: "btn-detail",
                  title: params.row.zqjc,
                },
                on: {
                  click: () => {
                    // this.$router.push({
                    //     path: `/Insight/financialMarket/financialProduct/Bond/convertibleDetail`,
                    //     query: {
                    //         id: params.row.id
                    //     }
                    // });
                    let routeData = this.$router.resolve({
                      path: `/Insight/financialMarket/financialProduct/Bond/convertibleDetail`,
                      query: {
                        id: params.row.id,
                      },
                    });
                    window.open(routeData.href, "_blank");
                  },
                },
              },
              params.row.zqjc
            );
          },
        },
        {
          title: "正股代码",
          align: "center",
          key: "zgdm",
          ellipsis: true,
          width: 150,
          tooltip: true,

          //   render: (h, params) => {
          //     return h('a', {
          //       attrs: {
          //         class: "btn-detail",
          //         title: params.row.zgdm,
          //       },
          //       on: {
          //         click: () => {
          //           getQy({
          //             code: params.row.zqdm
          //           }).then(res => {
          //             if (res.type == 1) {
          //               let routeUrl = this.$router.resolve({
          //                 path: `/Insight/enterprise/houseEnterprise/information`,
          //                 query: {
          //                   code: res.code
          //                 }
          //               });
          //               window.open(routeUrl.href, '_blank')
          //             } else if (res.type == 2) {
          //               let routeUrl = this.$router.resolve({
          //                 path: `/Insight/enterprise/bank/bankInformation`,
          //                 query: {
          //                   code: res.code
          //                 }
          //               });
          //               window.open(routeUrl.href, '_blank')
          //             } else {
          //               this.$Message.warning("未匹配到企业");
          //             }
          //           });
          //         }
          //       }
          //     }, params.row.zgdm)
          //   }
        },
        {
          title: "正股简称",
          align: "center",
          key: "zgjc",
          width: 150,
          ellipsis: true,
          tooltip: true,

          //   render: (h, params) => {
          //     return h('a', {
          //       attrs: {
          //         class: "btn-detail",
          //         title: params.row.zgjc,
          //       },
          //       on: {
          //         click: () => {
          //           getQy({
          //             code: params.row.zqdm
          //           }).then(res => {
          //             if (res.type == 1) {
          //               let routeUrl = this.$router.resolve({
          //                 path: `/Insight/enterprise/houseEnterprise/information`,
          //                 query: {
          //                   code: res.code
          //                 }
          //               });
          //               window.open(routeUrl.href, '_blank')
          //             } else if (res.type == 2) {
          //               let routeUrl = this.$router.resolve({
          //                 path: `/Insight/enterprise/bank/bankInformation`,
          //                 query: {
          //                   code: res.code
          //                 }
          //               });
          //               window.open(routeUrl.href, '_blank')
          //             } else {
          //               this.$Message.warning("未匹配到企业");
          //             }
          //           });
          //         }
          //       }
          //     }, params.row.zgjc)
          //   }
        },
        {
          title: "发行价格 （元）",
          align: "center",
          ellipsis: true,
          width: 150,
          tooltip: true,

          key: "fxjg",
           render: (h, params) => {
            return h(
              "span",
              params.row.fxjg?Number(params.row.fxjg).toFixed(2):params.row.fxjg
            );
          },
        },
        {
          align: "center",
          title: "发行总额(亿元)",
          ellipsis: true,
          tooltip: true,

          width: 150,
          key: "zqfxze",
           render: (h, params) => {
            return h(
              "span",
              params.row.zqfxze?Number(params.row.zqfxze).toFixed(2):params.row.zqfxze
            );
          },
        },
        /* {
                     title: '实际募集资金总额（亿元）',
                     align: 'center',
                     ellipsis: true,
                     "width": 150,

            tooltip: true,
            'tooltip-theme':'light',
                     key: 'sjmjzjze'
                 },*/
        {
          title: "正股价（元）",
          align: "center",
          ellipsis: true,
          width: 150,
          tooltip: true,

          key: "zgj",
           render: (h, params) => {
            return h(
              "span",
              params.row.zgj?Number(params.row.zgj).toFixed(2):params.row.zgj
            );
          },
        },
        {
          align: "center",
          title: "债券现价 （元）",
          ellipsis: true,
          tooltip: true,

          width: 150,
          key: "zqxj",
            render: (h, params) => {
            return h(
              "span",
              params.row.zqxj?Number(params.row.zqxj).toFixed(2):params.row.zqxj
            );
          },
        },
        {
          title: "转股价(元)",
          align: "center",
          ellipsis: true,
          width: 150,
          tooltip: true,

          key: "zgjj",
           render: (h, params) => {
            return h(
              "span",
              params.row.zgjj?Number(params.row.zgjj).toFixed(2):params.row.zgjj
            );
          },
        },

        {
          title: "上市日",
          align: "center",
          ellipsis: true,
          width: 150,
          tooltip: true,

          key: "ssr",
        },
        {
          title: "起息日",
          align: "center",
          ellipsis: true,
          width: 150,
          tooltip: true,

          key: "qxr",
        },
        {
          title: "到期日",
          align: "center",
          ellipsis: true,
          width: 150,
          tooltip: true,

          key: "dqr",
        },
        {
          title: "转股开始日",
          align: "center",
          ellipsis: true,
          width: 150,
          tooltip: true,

          key: "zgksr",
        },
        {
          title: "转股结束日",
          align: "center",
          ellipsis: true,
          width: 150,
          tooltip: true,

          key: "zgjsr",
        },
      ],
      tableLoading: false,
      total: 0,
      params: {
        current: 1,
        qxr: [],
        size: 10,
        ssr: [],
        zgdm: "",
        zgjc: "",
        zgksr: [],
        zqdm: "",
        zqjc: "",
      },
    };
  },
  mounted() {
    this.getKzz();
  },
  methods: {
    tableClick(row) {
      // this.$router.push({
      //     path: `/Insight/financialMarket/financialProduct/Bond/convertibleDetail`,
      //     query: {
      //         id: row.id
      //     }
      // });
      let routeData = this.$router.resolve({
        path: `/Insight/financialMarket/financialProduct/Bond/convertibleDetail`,
        query: {
          id: row.id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    getKzz() {
      this.tableLoading = true;
      queryKzz(this.params)
        .then((data) => {
          this.stockTable = data.records;
          this.total = data.total;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    pageChange(size) {
      this.params.current = size;
      this.getKzz();
    },
    getTimeStartzg(value) {
      this.$set(this.params.zgksr, 0, value);
    },
    getTimeEndzg(value) {
      this.$set(this.params.zgksr, 1, value);
    },
    getTimeStartss(value) {
      this.$set(this.params.ssr, 0, value);
    },
    getTimeEndss(value) {
      this.$set(this.params.ssr, 1, value);
    },
    getTimeStartqx(value) {
      this.$set(this.params.qxr, 0, value);
    },
    getTimeEndqx(value) {
      this.$set(this.params.qxr, 1, value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";
.convertibleBonds {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 20px;
}
.convertibleBonds {
  .topPart {
    width: 100%;
    height: 400px;
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    border: 1px solid rgba(227, 227, 229, 1);
    padding: 20px 30px;
    .form-item {
      margin-bottom: 20px;
      @include flex(flex-start);
      .inputBox {
        @include flex(flex-start);
      }
      .title {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: rgba(102, 102, 102, 1);
        line-height: 19px;
        margin-right: 20px;
        word-break: keep-all;
      }
      .addIssue {
        position: relative;
        .seletcTypeBox {
          position: absolute;
          left: 0;
          top: 20px;
          height: 40px;
          display: none;
          overflow: hidden;
          background: #fff;
          border: 1px solid rgba(227, 227, 229, 1);
          padding: 10px;
          z-index: 100;
        }
        &:hover .seletcTypeBox {
          display: block;
        }
      }
    }
  }
  .middlePart {
    margin: 20px 0;
  }
}
</style>
